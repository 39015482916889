/* Parsley Validation Config */

window.ParsleyConfig = {
  successClass: "",
  errorClass: "has-error",
  classHandler: function (ParsleyField) {
    return ParsleyField.$element.parents(".form-group");
  },
  errorsContainer: function (ParsleyField) {
    if(ParsleyField.$element.parent().hasClass('input-group')) {
      return ParsleyField.$element.parent().parent();
    }
    return ParsleyField.$element.parent();
  },
  errorsWrapper: '<span class="help-block"></span>',
  errorTemplate: '<span></span>',
  i18n: {
    id: {
      defaultMessage: "Tidak valid",
      type: {
        email:        "Email tidak valid",
        url:          "URL tidak valid",
        number:       "Nomor tidak valid",
        integer:      "Angka tidak valid",
        digits:       "Harus berupa digit",
        alphanum:     "Harus berupa digit atau huruf"
      },
      notblank:       "Tidak boleh kosong",
      required:       "Mohon diisi",
      pattern:        "Tidak valid",
      min:            "Harus lebih besar atau sama dengan %s",
      max:            "Harus lebih kecil atau sama dengan %s",
      range:          "Harus dalam rentang %s dan %s",
      minlength:      "Terlalu pendek, minimal %s karakter atau lebih",
      maxlength:      "Terlalu panjang, maksimal %s karakter atau kurang",
      length:         "Panjang karakter harus dalam rentang %s dan %s",
      mincheck:       "Pilih minimal %s pilihan",
      maxcheck:       "Pilih maksimal %s pilihan",
      check:          "Pilih antar %s dan %s pilihan",
      equalto:        "Harus sama"
    }
  }
};

if ('undefined' !== typeof window.ParsleyValidator)
  window.ParsleyValidator.addCatalog('id', window.ParsleyConfig.i18n.id, true);

window.ParsleyValidator.setLocale('id');

$(function() {

  // Digit only input
  $('body').on('keypress', '.digit-only', function (e) {
    if( e.which!=8 && e.which!=0 && e.which!=13 && (e.which<48 || e.which>57)) return false;
  });

});


